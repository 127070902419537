.Article {
    display: flex;
    position: relative;
    justify-content: center;
}

.Article .Container  {    
    display: flex;
    flex-direction: column;
    background-color: white;
    align-items: center;
    width: 70%;
    margin-top: 0;
    margin-bottom: 0;
    max-width: 60rem;
}

.Article .Title {
    margin: 2rem;
}

.Article .Content { 
    font-size: 1.2rem;
    margin: 0 5rem 3rem 5rem;
}

.a-Icons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    margin-top: 1rem;
    padding: 0;
}

.a-Icons li {
    list-style: none;
    display: inline;
    margin-right: .5rem;    
}

.Article .Title {    
    color:'#F01D43';
    margin: '.5rem';
}

@media only screen and (max-width: 768px) {

    .Article .Container  {  
        width: 100%;  
    }
    .Article .Content { 
        font-size: 1rem;
        margin: 0 2rem 2rem 2rem;
    }
}