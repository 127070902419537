.Header {
    display: flex;    
    position: relative;
    background-color: white;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 81px;
}
.Header a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;
    height: 100%;
    width: 100%;
    justify-content: center;
}

.Header .Container {
    display: flex;
    
    flex-direction: row;  
    align-items: center;
    
    width: 70%;      
    height: 100%;
    background-color: white; 
}

.Header .Container .Itens { 
    display: flex;
    flex-direction: row;
    align-items: center;
    list-style-type: none;
    margin: 0;
    height: 97%;
}

.Header .Container .Itens a:hover { 
    color: #1DF043;
}

.Header .Container .Itens li {
    display: flex;
    align-items: center;
    margin-left: 2em;
    white-space: nowrap;
    height: 100%;
}

.Header .Container .Itens li:hover{
    border-bottom: 3px solid #F01D43;
}

.Header .Container .Icons img {
    height: 30px;
} 

.Header .Container .Icons { 
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.Header .Container .Icons li {
    display: inline;
    margin-right: 2em;
}

.Header .Container .Icons li a:hover {
    color: #F01D43;
}

.Header .Mobile {
    display: none;
}

.Header .Logo{
    display: none;
}

@media only screen and (max-width: 768px) {
    .Header .Mobile {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: space-between;  
          
    }   

    .Header .Logo{
        display: flex;    
        align-items: center;
        justify-content: center;    
        margin-left: 1rem;
    }

    .Header .Mobile .Dropdown{
        right: 0;
        margin-right: 1rem;
    }

    .Header .Container {
        display: none;
    }

}

@media only screen and (min-width: 2560px) {

}