.c-Dropdown {
    width: 100%;
    margin: 0;
    margin-top: 0px;
    padding: 0;
    top: 80px;
    z-index: 10;    
    
    /* position: fixed;     */
    height: 100%;
    width: 100%;
}

.c-Dropdown button {
    border: none;
    background-color: white;
}
.c-Dropdown .DropdownOptions {
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
}

.c-Dropdown .DropdownOptions .Buttons{
    display: flex;
    flex-direction: column;
    justify-content: center;    
    padding: 1rem;
}

.c-Dropdown .DropdownOptions .Icons{
    display: flex; 
    justify-content: center;
    top: 0;
}

.Header .Mobile ul {    
    background-color: white;
    position: relative; 
    margin: 0; 
    padding: 0px;
    z-index: 10;
}

.Header .Mobile li {
    /* border: 1px solid black; */
    /* border-radius: 10px; */
    display: flex;
    justify-content: center;
    margin: 0;
    left: 0;  
    top: 0;
    padding: 10px;
    list-style: none;
    white-space: nowrap;
    text-align: center;
}

.Header .Mobile .Item:hover a {    
    color: #1DF043;
}

.Header .Mobile .Item:hover {    
    border-left: 3px solid #F01D43;
}

.DropdownButton {
    width: 40px;
    height: 40px;
}