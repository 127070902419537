.carousel-bullet {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: transparent;
    border: 2px solid white;
    cursor: pointer;
}

.carousel-image {
    max-width: '100%';
    max-height: 300;
}