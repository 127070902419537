.Footer {    
	bottom: 0;
    width: 100%;
    position: relative;
}

.d-Footer {
    background-color: #A83248;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    font-size: .8rem;
}

.u-Footer {
    flex-direction: column;
    background-color: #F01D43;
    color: white;
    font-size: .1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 150px;
	bottom: 2rem;
    padding: 0;
}

.Footer .u-Footer .Terms {
    text-decoration: none;
    color: white;
    margin: 0;
    border: none;
    padding: 0;
    background-color: transparent;
}

.Footer .u-Footer .Terms:hover {
    font-weight: bold;
}

.u-Footer ul li{ 
    display: inline;
    margin-right: .4rem;
}

.u-Footer ul {  
    display: flex;
    list-style: none;
    font-size: .8rem;
    margin-left: 0;
    padding-left: 0;
    margin: 0;
    margin-bottom: .3rem;
}

.u-Footer .Logo { 
    width: 5rem;
    height: 5rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
}
.u-Footer .Icon {  
    width: 1.5rem;
    height: 1.5rem
}